<template>
  <div>
    <div id="header">
      <v-img
        alt="Venzo Logo"
        class="shrink ml-auto"
        contain
        src="@/assets/venzologo.png"
        transition="scale-transition"
        width="150px"
         height="60px"
      />
    </div>
    <div>
      {{ filedata }}
    </div>
    <p>&nbsp;</p>
    <strong><strong>Letter of Employment</strong></strong>
    <br />
    <div>{{ Todaydate }}</div>
    <!--inputs -->
    <br />
    <div>Dear {{ candidateName }},</div>
    <div>Chennai, India.</div>
    <br />
    <!-- //Todaydate,candidateName,descriptions,TotalCTC,TotalCTC-->
    <p>Dear {{ candidateName }},</p>
    <!--inputs -->
    <p>&nbsp;</p>
    <p>
      With reference to your application and the subsequent interviews, we are pleased to offer you employment as a
      <strong
        ><strong>{{ descriptions }} </strong></strong
      >in our organization.
    </p>
    <p>&nbsp;</p>
    <p>
      As detailed in page 2, your annual compensation will be INR {{ TotalCTC }} /- (Indian Rupees
      {{ TotalCTCInWord }} Only).
    </p>
    <p>&nbsp;</p>
    <p>
      The designated work location will be at our main office at 8<sup>th</sup>&nbsp;Floor, Purva Primus, Old
      Mahabalipuram Road, Thoraipakkam, Chennai 600 097.
    </p>
    <p>&nbsp;</p>
    <p>
      The terms of your employment with the company are detailed as part of this offer letter from page 3 onwards. Your
      employment is contingent upon your agreement to these terms and conditions, as evidenced by your signing of the
      last page of this letter and returning it to us by {{ signingdate }}.
    </p>
    <p>&nbsp;</p>
    <p>Looking forward to welcome you on board at Venzo!</p>
    <p>&nbsp;</p>
    <p>Thanking you,</p>
    <p>&nbsp;</p>
    <p>For Venzo Consultancy Services LLP</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>Asif Aly</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <br /><br /><br /><br />
    <p>Managing Partner</p>

    <div style="page-break-after: always"></div>

    <br /><br />
    <p>
      <strong><strong>Compensation Structure</strong></strong>
    </p>

    <div><h3 align="center">Annual Compensation</h3></div>
    <v-simple-table dense class="table">
      <template v-slot:default>
        <tbody>
          <tr v-for="item in saldata" :key="item.no">
            <td text-align="left">{{ item.mainname }}</td>
            <td text-align="left">{{ item.subname }}</td>
            <td align="right">{{ item.number }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div><h3 align="center">Monthly Salary Indicator</h3></div>
    <v-simple-table dense class="table">
      <template v-slot:default>
        <tbody>
          <tr v-for="item in mondata" :key="item.no">
            <td text-align="left">{{ item.mainname }}</td>
            <td text-align="left">{{ item.subname }}</td>
            <td align="right">{{ item.number }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <p>
      In addition to the above Medical Insurance for self + spouse + 2 children + dependent parents (@50% cost to
      employee) will be provided
    </p>

    <p>
      * Flexible Allowances Plan (FAP) is a combination of taxable and non-taxable allowance such as Leave Travel
      Allowance (LTA), Medical Reimbursement, Lunch Allowance, Conveyance Allowance etc
    </p>

    <p>** Based on Individual and Company performance, paid annually</p>

    <div style="page-break-after: always"></div>

    <br /><br />
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      <strong><strong>&nbsp;</strong></strong>
    </p>
    <p>
      <strong><strong>Terms &amp; Conditions</strong></strong>
    </p>
    <p>&nbsp;</p>
    <p>
      Venzo Consultancy Services LLP will hereinafter be referred to as the &lsquo;Company&rsquo; or the &lsquo;The
      Employer.
    </p>
    <p>&nbsp;</p>
    <ol>
      <li>
        <strong><strong> &nbsp;Our Offer Letter</strong></strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      Once you receive your signed offer letter, which means we have all approvals from our management and our clients
      to bring you on board. At this point you can resign from your current employer.
    </p>
    <p>&nbsp;</p>
    <ol start="2">
      <li>
        <strong><strong> Resigning from your Current Company</strong></strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      We will need to see valid proof of your resignation from your current employer when you send us your acceptance of
      the offer (a copy of email you sent to your HR / Manager would suffice). You can submit your resignation with your
      current employer as soon as you receive our signed offer letter. Within 15 days from acceptance of the offer by
      you, please send us proof of acceptance of your resignation from your Current Company.
    </p>
    <p>&nbsp;</p>
    <ol start="3">
      <li>
        <strong><strong> Relieving Letters</strong></strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      At the time of joining you would be required to submit the relieving letters from all your previous employers. In
      case of any exceptions, please notify us in advance, so we can handle your specific case.
    </p>
    <p>&nbsp;</p>
    <ol start="4">
      <li>
        <strong><strong> Probationary Period</strong></strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      Your employment is subject to your and Company&rsquo;s satisfactorily completing, a probationary period of 6 (six)
      months. At the end of 6 months, you will be automatically deemed to be a confirmed employee, if no notice to the
      contrary is provided.
    </p>
    <p>&nbsp;</p>
    <p>
      Depending on the performance of an employee, the management may at its own discretion or in consultation with the
      client may confirm the employee before the probation period.
    </p>
    <p>&nbsp;</p>
    <ol start="5">
      <li>
        <strong><strong> Travel</strong></strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      You may be required to travel to client locations for training or project engagements overseas as agreed between
      the company and its clients or partners. You may have to work at client locations for specific periods of time as
      required for the services of the company. The company will pay for all additional costs incurred during such
      travel (air-fare, accommodation, visa, insurance and such) along with a per-diem as per company policies
      prevailing at that time.
    </p>
    <p>&nbsp;</p>
    <div style="page-break-after: always"></div>
    <br /><br /><br /><br />
    <ol start="6">
      <li>
        <strong><strong> Salary</strong></strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      Your salary is stated in page 2 of this offer letter. You will be paid a monthly salary (subject to the
      appropriate statutory deductions if any applicable) payable monthly by Bank Transfer before the 1st of every
      month. Annual
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      salary increments will be based on the overall Terms and Conditions of Employment, performance of the individual
      and the company as per the performance management policies of the company. You will be notified
    </p>
    <p>
      in writing of any changes to your salary, which remains at the sole discretion of the company. Any history of
      increases in salary, bonuses or special awards, should not be taken as entitlement to any future eligibility.
    </p>
    <p>&nbsp;</p>
    <p>
      The company does not provide any other facilities/compensation other than those detailed in compensation structure
      in page 2.
    </p>
    <p>&nbsp;</p>
    <p>
      Your salary is strictly confidential and you are expected to maintain confidentiality. Any breach of this
      confidence will be viewed with utmost seriousness.
    </p>
    <p>&nbsp;</p>
    <ol start="7">
      <li>
        <strong><strong> Work Timings</strong></strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>You will be required to be present in the office for a minimum of 9 hours a day for 5 days a week.</p>
    <p>&nbsp;</p>
    <p>
      You may be required to work overtime occasionally, in such cases no overtime charges will be paid. However, the
      management at its own discretion might decide to compensate for the extra hours.
    </p>
    <p>&nbsp;</p>
    <p>
      The regular working week is Monday - Friday. There is a possibility that the working week might be changed
      depending on client&rsquo;s needs, i.e. you might be required to work Sunday - Thursday or similar as per the
      roster decided by the management.
    </p>
    <p>&nbsp;</p>
    <p>
      Our office will open at 8 AM and close at 9 PM. We might stagger the start and end time of employees depending on
      work/client needs.
    </p>
    <p>&nbsp;</p>
    <ol start="8">
      <li>
        <strong><strong> Holidays and Leave Entitlements</strong></strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      The company&rsquo;s holiday year runs from 1st January to 31st December inclusive. The annual leave entitlement is
      24 days (including sick, casual etc.) and should be taken only with prior approval from the line manager to meet
      staffing requirements. If sick leave is taken for more than 3 days continuously, then a medical certificate should
      be submitted.
    </p>
    <p>&nbsp;</p>
    <br /><br /><br />
    <p>
      The annual leave entitlement will be credited as 2 days per month and leave not taken in the year may be paid or
      carried over to the next consecutive year in accordance with the leave policy in force. If you start or leave
      employment any time during the calendar year, your leave entitlement (annual and public) will be prorated for that
      calendar year and rounded up to the nearest full working day.
    </p>
    <p>&nbsp;</p>
    <p>Maternity benefits as per the government norms can be availed only after completion of one year of continuous employment.</p>
    <p>&nbsp;</p>
    <ol start="9">
      <br />
      <li>
        <strong><strong> Termination</strong></strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      During or at the end of the probationary period stated in point no. 4 above, the Company can terminate your
      employment due to poor performance, with 15 days prior written notice.
    </p>
    <p>&nbsp;</p>
    <p>
      After probation period, the company can terminate your employment at any time by serving a notice period of 30
      (thirty) days.
    </p>
    <p>
      <strong><strong>&nbsp;</strong></strong>
    </p>
    <p>
      <strong><strong>&nbsp;</strong></strong>
    </p>
    <p>
      <strong><strong>&nbsp;</strong></strong>
    </p>
    <p>
      <strong><strong>&nbsp;</strong></strong>
    </p>
    <p>
      <strong><strong>&nbsp;</strong></strong>
    </p>
    <ol start="10">
      <li>
        <strong><strong> Immediate termination</strong></strong>
      </li>
    </ol>
    <p>
      The Company may terminate your employment with immediate effect without notice or pay in lieu of notice, if you
      are guilty of gross misconduct or gross negligence; commit any serious or (after warning) repeated or
      &nbsp;continued breach of your obligations to the Company; are convicted of any criminal offence; fail in the
      reasonable opinion of the Company to perform your duties to a satisfactory standard; do anything that might
      reasonably bring the Company into disrepute; or act in a way which will be materially adverse to the interests of
      the Company.
    </p>
    <p>&nbsp;</p>
    <p>
      Seeking direct employment with the Company&rsquo;s client or with other 3rd party vendors of the client can also
      result in a breach of the code of conduct and this would result in immediate termination. Upon termination, there
      will be one-year period where you cannot seek employment with any of our clients or with other 3rd party vendors
      of the client.
    </p>
    <p>&nbsp;</p>
    <ol start="11">
      <li>
        <strong><strong> Resignation</strong></strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      In case of termination of employment due to your resignation, you will be required to serve a notice period of 90
      (ninety) days. However, this would be negotiable depending on the staffing requirements at the time of your
      resignation.
    </p>
    <p>&nbsp;</p>
    <p>
      In case of resignation before completion of one year in the company, all expenses incurred for training outside
      Chennai region shall be deducted from the final settlement.
    </p>
    <p>&nbsp;</p>
    <p>
      If your salary contains a loyalty incentive, management reserves the right to hold the payment of loyalty
      incentive if employee resigns before completion of 1 year with the company.
    </p>
    <p>&nbsp;</p>
    <div style="page-break-after: always"></div>
    <br /><br /><br /><br />
    <ol start="12">
      <li>
        <strong><strong> Confidentiality</strong></strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      During the course of your employment you will be exposed to certain Organisation's trade secrets consisting of,
      but not necessarily limited to technical information including methods, processes, formulae, systems, techniques,
      inventions, machines, computer programs and research projects, and business information including customer lists,
      pricing data, sources of supply, financial data and marketing, production, or merchandising systems or plans.
    </p>
    <p>&nbsp;</p>

    <p>
      Hence you are informed that you shall not, during or at any time after the termination of your employment with the
      Organisation, disclose or divulge to others including future employers, any trade secrets, confidential
      information, or any other proprietary data of the Organisation in violation of this agreement.
    </p>
    <p>&nbsp;</p>
    <p>
      Upon the termination of your employment from the Organisation you shall return all (and not retain any) documents
      and property of the Organisation, including (but not necessarily limited to) drawings, blueprints, reports,
      manuals, correspondence, customer lists, computer programs, and any material relating to the Organisation's
      business, or obtained by you during the course of your employment.
    </p>
    <p>
      <strong><strong>&nbsp;</strong></strong>
    </p>

    <ol start="13">
      <li>
        <strong><strong> General</strong></strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      You are required to adhere to the disciplinary requirements of the Organisation. It is an express condition that
      the Organisation&rsquo;s personnel are whole-time employees and accordingly, you shall devote your time to the
      Organisation&rsquo;s business.
    </p>
    <p>&nbsp;</p>
    <p>
      You shall not, without express permission, undertake any other business, work, or public office, on payment or
      otherwise. In the event of breach of this condition on your part, you will be liable for termination of your
      services with immediate effect.
    </p>
    <p>&nbsp;</p>
    <p>
      You shall not disclose, divulge or make public any of the process, accounts, transactions or dealings of the
      organisation at any time without the consent of organisation, except under legal obligation.
    </p>
    <p>&nbsp;</p>
    <p>
      You shall diligently and faithfully carry out instructions given to you by your superiors in connection with the
      business and to the best of your power, skill and ability, to improve the business and interest of the
      organisation.
    </p>
    <p>&nbsp;</p>
    <p>
      The result of all the researches carried out by you during your employment would be the property of the
      organisation and such patents that may be granted to you shall also be assigned to the organisation. You shall
      also not seek to obtain patent rights on any work or process carried out by you without the consent of the
      organisation.
    </p>
    <p>&nbsp;</p>
    <br /><br /><br />
    <p>
      Your appointment is contingent upon satisfactory reference and background checks including verification of your
      application materials, education and employment history. Your employment is also contingent upon your ability
      to work for the Company without restriction (i.e. you do not have any non-compete obligations or other
      restrictive clauses with any previous employer).
    </p>
    <p>&nbsp;</p>
    <p>
      You are appointed on the basis of your educational qualification, experience and other relevant information
      provided by you in your resume. In case if any of the particulars furnished therein are found to be false or
      incorrect, your services are liable to be terminated without any notice or compensation.
    </p>
    <p>&nbsp;</p>
    <p>
      You will intimate the organisation, any change of address within a week from the change of the same, failing which
      any communication sent to your last recorded address shall be deemed to have been served to you.
    </p>
    <p>&nbsp;</p>

    <!--<div style='page-break-after:always'></div>
 <br><br><br><br>-->
    <ol start="14">
      <li>
        <strong><strong> Code of Conduct</strong></strong>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      You are not authorised to represent the Organisation at any public forums, press meetings / conferences or publish
      articles in magazines/newspapers unless otherwise authorised by the Director or other person authorised by the
      Management. Any views expressed in your personal capacity will be treated as your own view and not that of the
      Organisation. The Organisation will not be responsible or liable for any claims that may arise out of such views.
    </p>
    <p>&nbsp;</p>
    <p>
      You will be bound by the rules and regulations as declared by the Management, from time to time, in relation to
      conduct, discipline, medical fitness, leave, holidays and all matters relating to terms and conditions of service,
      network security and usage.
    </p>
    <p>&nbsp;</p>
    <p>
      You will abide by all statutory and regulatory laws at all times and violation of any of these will attract
      immediate termination of employment.
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>

    <div style="page-break-after: always"></div>
    <br /><br /><br /><br />

    <p>Acceptance of Letter of Employment</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>
      I accept this offer for employment and confirm that I will report to work on
      <strong><strong>____________</strong></strong
      >&nbsp;(joining date) at the designated work location.
    </p>
    <p>&nbsp;</p>
    <p>
      I have initiated the resignation process in my current company and will provide proof of the same, if required.
    </p>
    <p>&nbsp;</p>
    <p>
      I have read the offer letter, compensation details, terms and conditions and understand that my employment at
      Venzo will be governed by these.
    </p>
    <p>&nbsp;</p>
    <p>
     I fully understand that based on my acceptance of this agreement Venzo may already incur costs for my onboarding, start work planning and enter into binding client commitments.
    </p>
    <p>&nbsp;</p>
    <p>
      Thus, if I am unable to take up the employment or if there is a delay of more than 2 weeks in my joining date (other than due to proven force majeure events, personal emergenices), I will notify Venzo within 10 days of my acceptance of this agreement
    </p>
    <p>&nbsp;</p>
    <p>
     I accept that failing to notify Venzo within the stipulated time will be construed as a breach of this agreement and I can be held liable for damages upto a total of 2 months gross salary proposed in the offer.
    </p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;</p>
    <p>{{ candidateName }}</p>

    <v-btn id="saveit" v-if="savebuttion" @click="saveus"> Save </v-btn>
  </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin.js"
import { emailMixin } from "@/mixins/emailMixin.js"
import { ToWords } from "to-words"
import { recruitmentRef } from "@/fb"
//import {fs} from 'fs'
//import {fs} from "../../fb"
//import {fs} from "../../fb"
export default {
  name: "offerletter",
  props: ["roles"],
  mixins: [formMixin, emailMixin],
  data: () => ({
    TotalCTC: 0,
    BasicandAllowances: "",
    BasicSalary: "",
    HRA: "",
    FlexiableAllowances: "",
    VariablePay: "",
    compayContributions: "",
    PF: "",
    Gratuity: "",

    GrossMonthlySalary: "",
    MonBasicSalary: "",
    MonHRA: "",
    MonFlexiableAllowances: "",
    MonPF: "",
    MonProfessionalTax: 0,
    Montakehome: "",
    MonDeductions: 0,
    MonTakeHome: "",
    saldata: [],
    mondata: [],
    Todaydate: "",
    TotalCTCInWord: "",
    candidateName: "",
    descriptions: "",
    signingdate: "",
    filedata: "",
    savebuttion: true,
  }),
  created() {
    console.log("offer letter crated", this.roles)
    

    this.TotalCTC = parseInt(this.roles.offeredCTC)
    this.VariablePay = parseInt(this.roles.VariablePay)
    this.MonProfessionalTax = parseInt(this.roles.professionalTax)
    var tmpsigningdate = this.roles.sigingdate
    var bpratio = this.roles.bpratio
    this.candidateName = this.roles.candidateName
    this.descriptions = this.roles.title

    console.log("This tmpsigningdate ", tmpsigningdate)

    this.BasicSalary = (this.TotalCTC * parseInt(bpratio)) / 100
    this.HRA = (this.BasicSalary * 50) / 100
    this.PF = (this.BasicSalary * 12) / 100
    this.Gratuity = (this.BasicSalary * 5) / 100
    this.compayContributions = this.PF + this.Gratuity
    this.BasicandAllowances = this.TotalCTC - this.VariablePay - this.compayContributions
    this.FlexiableAllowances = this.BasicandAllowances - (this.BasicSalary + this.HRA)

    this.GrossMonthlySalary = this.BasicandAllowances / 12
    this.MonBasicSalary = this.BasicSalary / 12
    this.MonHRA = this.HRA / 12
    this.MonFlexiableAllowances = this.FlexiableAllowances / 12

    this.MonPF = this.PF / 12
    // this.MonProfessionalTax=this.roles.ProfessionalTax;
    this.MonDeductions = this.MonPF + this.MonProfessionalTax
    this.MonTakeHome = this.GrossMonthlySalary - this.MonDeductions

    // Todaydate,candidateName,descriptions,TotalCTC,TotalCTC;;
    var tmpdate = new Date().toISOString().substr(0, 10)
    this.Todaydate = this.convertDate(tmpdate)
    var datediff = new Date(tmpsigningdate) - new Date()
    datediff = datediff / (1000 * 3600 * 24)
    tmpdate = new Date(Date.now() + datediff * 24 * 60 * 60 * 1000).toISOString().substr(0, 10)
    this.signingdate = this.convertDate(tmpdate)

    this.TotalCTC = this.TotalCTC.toFixed(2)
    this.VariablePay = this.VariablePay.toFixed(2)
    this.BasicSalary = this.BasicSalary.toFixed(2)
    this.HRA = this.HRA.toFixed(2)
    this.PF = this.PF.toFixed(2)
    this.Gratuity = this.Gratuity.toFixed(2)
    this.compayContributions = this.compayContributions.toFixed(2)
    this.BasicandAllowances = this.BasicandAllowances.toFixed(2)
    this.FlexiableAllowances = this.FlexiableAllowances.toFixed(2)
    this.GrossMonthlySalary = this.GrossMonthlySalary.toFixed(2)
    this.MonBasicSalary = this.MonBasicSalary.toFixed(2)
    this.MonHRA = this.MonHRA.toFixed(2)
    this.MonFlexiableAllowances = this.MonFlexiableAllowances.toFixed(2)
    this.MonPF = this.MonPF.toFixed(2)
    //  this.MonDeductions=this.MonDeductions.toFixed(2);
    this.MonTakeHome = this.MonTakeHome.toFixed(2)

    // console.log("TotalCTC", this.TotalCTC)
    // console.log("VariablePay", this.VariablePay)
    // console.log("MonProfessionalTax", this.MonProfessionalTax)
    // console.log("BasicSalary", this.BasicSalary)
    // console.log("HRA", this.HRA)
    // console.log("PF", this.PF)
    // console.log("Gratuity", this.Gratuity)
    // console.log("compayContributions", this.compayContributions)
    // console.log("BasicandAllowances", this.BasicandAllowances)
    // console.log("FlexiableAllowances", this.FlexiableAllowances)
    // console.log("GrossMonthlySalary", this.GrossMonthlySalary)
    // console.log("MonBasicSalary", this.MonBasicSalary)
    // console.log("MonHRA", this.MonHRA)
    // console.log("MonFlexiableAllowances", this.MonFlexiableAllowances)
    // console.log("MonPF", this.MonPF)
    // console.log("MonDeductions", this.MonDeductions)
    // console.log("MonTakeHome", this.MonTakeHome)

   
    const toWords = new ToWords()
    this.TotalCTCInWord = toWords.convert(this.TotalCTC)
    

    var Formatter = new Intl.NumberFormat("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

    this.saldata.push({ no: 1, mainname: "", subname: "", number: "Amount(in INR)" })
    this.saldata.push({ no: 2, mainname: "Total CTC", subname: "", number: Formatter.format(this.TotalCTC) })
    this.saldata.push({
      no: 3,
      mainname: "BasicandAllowances",
      subname: "",
      number: Formatter.format(this.BasicandAllowances),
    })
    this.saldata.push({ no: 4, mainname: "", subname: "BasicSalary", number: Formatter.format(this.BasicSalary) })
    this.saldata.push({ no: 5, mainname: "", subname: "House Rent Allowance", number: Formatter.format(this.HRA) })
    this.saldata.push({
      no: 6,
      mainname: "",
      subname: "Flexible Allowances Plan *",
      number: Formatter.format(this.FlexiableAllowances),
    })

    this.saldata.push({
      no: 7,
      mainname: "Variable Pay (at 100% payout) **",
      subname: "",
      number: Formatter.format(this.VariablePay),
    })

    this.saldata.push({
      no: 8,
      mainname: "Company Contributions",
      subname: "",
      number: Formatter.format(this.compayContributions),
    })
    this.saldata.push({ no: 9, mainname: "", subname: "Provident Fund", number: Formatter.format(this.PF) })
    this.saldata.push({ no: 10, mainname: "", subname: "Gratuity", number: Formatter.format(this.Gratuity) })

    //this.saldata.push({no:21,mainname:"--------------------------------------",subname:"-----------------------",number:""});

    // this.mondata.push({no:11,mainname:'',subname:'Monthly Salary Indicator ',number:''})
    this.mondata.push({
      no: 12,
      mainname: "Gross Monthly Salary",
      subname: "",
      number: Formatter.format(this.GrossMonthlySalary),
    })
    this.mondata.push({ no: 13, mainname: "", subname: "Basic Salary", number: Formatter.format(this.MonBasicSalary) })
    this.mondata.push({ no: 14, mainname: "", subname: "House Rent Allowance", number: Formatter.format(this.MonHRA) })
    this.mondata.push({
      no: 15,
      mainname: "",
      subname: "Flexible Allowances Plan",
      number: Formatter.format(this.MonFlexiableAllowances),
    })

    this.mondata.push({ no: 16, mainname: "Deductions", subname: "", number: Formatter.format(this.MonDeductions) })
    this.mondata.push({
      no: 17,
      mainname: "",
      subname: "Employee PF Contribution at 12%",
      number: Formatter.format(this.MonPF),
    })
    this.mondata.push({
      no: 18,
      mainname: "",
      subname: "Professional Tax (approx)",
      number: Formatter.format(this.MonProfessionalTax),
    })

    this.mondata.push({
      no: 19,
      mainname: "Monthly take home before tax",
      subname: "",
      number: Formatter.format(this.MonTakeHome),
    })

    //const fs = require("fs");
    //console.log("fs is",fs)
    //this.filedata=fs.readFileSync('letter1.docx','utf-8');

    /* }
         else
         { 
            alert("Not authorized to access this page , kindly contact support");
            this.$router.replace({ name: this.roles.menufrom})
         }
       }
       else
       { 
         alert("Not authorized to access this page , kindly contact support");
           this.$router.replace({ name: "/login"});
       }*/
  },
  methods: {
    saveus() {
      this.savebuttion = false
      this.$emit("disablenav")
      var myDiv = document.getElementById("saveit")
      myDiv.style.visibility = "hidden"

      recruitmentRef.doc(this.roles.profileid).update({
        status: "Offer Printed",
      })
      window.print()
    },
    convertDate(
      tmpdate // to convert date in to readable format eg 12-10-2020 will be shown as 12-Oct-2020
    ) {
      // console.log("inside convert date",tmpdate);
      if (tmpdate) {
        var year = tmpdate.substring(0, 4)
        var month = parseInt(tmpdate.substring(5, 7))
        var date = tmpdate.substring(8, 10)

        var newmonth
        switch (month) {
          case 1:
            newmonth = " January "
            break
          case 2:
            newmonth = " February"
            break
          case 3:
            newmonth = " March "
            break
          case 4:
            newmonth = " April "
            break
          case 5:
            newmonth = " May "
            break
          case 6:
            newmonth = " June "
            break
          case 7:
            newmonth = " July "
            break
          case 8:
            newmonth = " August "
            break
          case 9:
            newmonth = " September "
            break
          case 10:
            newmonth = " October "
            break
          case 11:
            newmonth = " November "
            break
          case 12:
            newmonth = " December "
            break
        }
        var newdate = date + newmonth + year
        // console.log("newdate",newdate)
        return newdate
      } else {
        return newdate
      }
    },
  }, //end of methods
}
</script>

<style scoped>
div#header {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;

  background: white;
  padding: 8px;
}
@media print {
  .no-print {
    display: none;
  }
  .v-content {
    padding: 0 !important;
  }
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 80%;
}

td,
th {
  border: 1px solid #dddddd;
  /* text-align: left;*/
  padding: 8px;
}

tr:nth-child(even) {
  background-color: white;
}
tr:nth-child(odd) {
  background-color: white;
}
.fixed-header,
.fixed-footer {
  width: 100%;
  position: fixed;
  background: green;
  padding: 10px 0;
  color: blue;
}
.fixed-header {
  top: 0;
}
.fixed-footer {
  bottom: 0;
}
</style>
